// --------------------------------

// (START) Global editor code https://codyhouse.co/ds/globals/colors

// --------------------------------

:root, [data-theme="default"] {
  // main
  @include defineColorHSL(--color-primary-darker, 59, 81%, 40%);
  @include defineColorHSL(--color-primary-dark, 59, 81%, 50%);
  @include defineColorHSL(--color-primary, 59, 81%, 60%);
  @include defineColorHSL(--color-primary-light, 59, 81%, 70%);
  @include defineColorHSL(--color-primary-lighter, 59, 81%, 80%);

  @include defineColorHSL(--color-accent-darker, 0, 0%, 20%);
  @include defineColorHSL(--color-accent-dark, 0, 0%, 30%);
  @include defineColorHSL(--color-accent, 0, 0%, 40%);
  @include defineColorHSL(--color-accent-light, 0, 0%, 50%);
  @include defineColorHSL(--color-accent-lighter, 0, 0%, 60%);

  @include defineColorHSL(--color-black, 240, 8%, 12%);
  @include defineColorHSL(--color-white, 0, 0%, 100%);

  // feedback
  @include defineColorHSL(--color-warning-darker, 46, 100%, 47%);
  @include defineColorHSL(--color-warning-dark, 46, 100%, 50%);
  @include defineColorHSL(--color-warning, 46, 100%, 61%);
  @include defineColorHSL(--color-warning-light, 46, 100%, 71%);
  @include defineColorHSL(--color-warning-lighter, 46, 100%, 80%);

  @include defineColorHSL(--color-success-darker, 94, 48%, 42%);
  @include defineColorHSL(--color-success-dark, 94, 48%, 48%);
  @include defineColorHSL(--color-success, 94, 48%, 56%);
  @include defineColorHSL(--color-success-light, 94, 48%, 65%);
  @include defineColorHSL(--color-success-lighter, 94, 48%, 74%);

  @include defineColorHSL(--color-error-darker, 349, 75%, 36%);
  @include defineColorHSL(--color-error-dark, 349, 75%, 44%);
  @include defineColorHSL(--color-error, 349, 75%, 51%);
  @include defineColorHSL(--color-error-light, 349, 75%, 59%);
  @include defineColorHSL(--color-error-lighter, 349, 75%, 67%);

  // color contrasts
  @include defineColorHSL(--color-bg, 0, 0%, 100%);
  @include defineColorHSL(--color-contrast-lower, 0, 0%, 95%);
  @include defineColorHSL(--color-contrast-low, 240, 1%, 83%);
  @include defineColorHSL(--color-contrast-medium, 240, 1%, 48%);
  @include defineColorHSL(--color-contrast-high, 240, 4%, 20%);
  @include defineColorHSL(--color-contrast-higher, 240, 8%, 12%);
}

// --------------------------------

// (END) Global editor code

// --------------------------------