//
// Custom
// --------------------------------------------------
:root {
	--header-height: 90px;

	@media (min-width: 360px) {
		--header-height: 125px;
	}
}

@media (max-width: 1023px) {
	.header__nav {
		transition: opacity 0.3s;
		left: -101%;
		opacity: 0;
		display: block;

		&.header__nav--is-visible {
			left: 0;
			opacity: 1;
		}
	}
}

.header__logo {
	width: 75%;
}

@media (min-width: 480px) {
	.header__logo {
		width: auto;
	}
}

.header {
	transition: height 0.3s;
}

.header__link {
	transition: color 0.3s;
}

.text-component {
	h1,
	h2,
	h3,
	h4 {
		line-height: var(--heading-line-height);
		margin-top: 0;
	}

	.h2 {
		@extend h2;
	}

	.h3 {
		@extend h3;
	}

	ul,
	ol {
		list-style-position: outside;
		padding-left: var(--space-md);
	}

	ul:last-child,
	ol:last-child,
	p:last-child {
		margin-bottom: 0;
	}

	table {
		margin-bottom: 1rem;
		tr {
			border-top: 1px solid var(--color-contrast-low);
			&:last-child {
				border-bottom: 1px solid var(--color-contrast-low);
			}
		}
		td {
			padding-right: 1rem;
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
		}
	}
}

.overscroll-section__sticky-content {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	background-color: var(--color-primary-lighter);

	// When fading content below, a pixel line is shown,
	// by placing a border bottom the line disapears
	border-bottom: 1px solid #fff;
}

.card-v9--overlay-bg {
	&::before {
		content: '';
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: transparent;
		transition: background-color 0.3s;
	}
	&:hover::before {
		background-color: alpha(var(--color-bg), 0.25);
	}
}

.main-footer {
	background-color: var(--color-primary-lighter);

	a {
		color: #000;
	}
}

.footer-logo {
	text-align: right;
}
